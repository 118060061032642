import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { Button } from 'reactstrap';

import SavedTeams from '../providers/savedTeamsProvider';
import Icon from '../common/icon';
import FeedbackMessage from '../common/feedbackMessage';

const SaveTeam = ({ id, name }) => {
  const { savedTeams, setSavedTeams } = useContext(SavedTeams);
  const [isSavedTeam, setIsSavedTeam] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState(null);

  useEffect(() => {
    if (savedTeams.length && savedTeams.find(t => t.id === id)) {
      setIsSavedTeam(true);
    } else {
      setIsSavedTeam(false);
    }
  }, [id, savedTeams]);

  const saveTeam = () => {
    const teams = [...savedTeams, { name, id }];
    setSavedTeams(teams);
    setIsSavedTeam(true);
    setFeedbackMessage('teamSaved');
  };

  const removeTeam = () => {
    const i = savedTeams.findIndex(t => t.id === id);
    const teams = [...savedTeams];
    teams.splice(i, 1);
    setSavedTeams(teams);
    setIsSavedTeam(false);
    setFeedbackMessage('teamRemoved');
  };
  
  const buttonComponent = () => {
    if (isSavedTeam) {
      return (
        <Button onClick={removeTeam} color="link" class="narrow">
          <Icon name="heart_filled" class="primary" size="48" />
        </Button>
      );
    }
    return (
      <Button onClick={saveTeam} color="link" class="narrow">
        <Icon name="heart" class="primary" size="48" />
      </Button>
    );
  };

  return (
    <div>
      {buttonComponent()}
      <FeedbackMessage messageKey={feedbackMessage} />
    </div>
  );

};

export default SaveTeam;