const type = (obj, fullClass) => {

  // get toPrototypeString() of obj (handles all types)
  // Early JS environments return '[object Object]' for null, so it's best to directly check for it.
  if (fullClass) {
    return (obj === null) ? '[object Null]' : Object.prototype.toString.call(obj);
  }
  // eslint-disable-next-line eqeqeq
  if (obj == null) return (`${obj}`).toLowerCase();

  let deepType = Object.prototype.toString.call(obj).slice(8,-1).toLowerCase();
  if (deepType === 'generatorfunction') return 'function';

  // Prevent overspecificity (for example, [object HTMLDivElement], etc).
  // Account for functionish Regexp (Android <=2.3), functionish <object> element (Chrome <=57, Firefox <=52), etc.
  // String.prototype.match is universally supported.

  return deepType.match(/^(array|bigint|date|error|function|generator|regexp|symbol)$/) ? deepType :
    (typeof obj === 'object' || typeof obj === 'function') ? 'object' : typeof obj;
};

export default type;