import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';

import AutoComplete from '../common/autoComplete';

import t from '../../util/i18n';
import { formApiUrl, getLinkUrl } from '../../assets/urls';
import { customFetch } from '../../util/customFetch';

const TeamSearch = ({ searchCallback, inputId }) => {
  const [searchInput, setSearchInput] = useState('');
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const result = await customFetch(formApiUrl('teams'));
        const teams = result.teams.map(team => ({
          name: team.team_name.toString(),
          id: team.team_id,
        }));
        setTeams(teams.sort((a, b) => (a.name > b.name) ? 1 : -1));
      } catch (error) {
        console.error(error);
      }
    };

    fetchTeams();
  }, []);

  const openTeamData = (selectedTeam) => {
    setSearchInput(selectedTeam.name);
    console.log(`/${getLinkUrl('team')}/${selectedTeam.id}`);
    route(`/${getLinkUrl('team')}/${selectedTeam.id}`, true);
    setSearchInput('');

    searchCallback !== undefined && searchCallback();
  };

  return (
    <AutoComplete
      suggestions={teams}
      onSuggestionSelect={(selected) => openTeamData(selected)}
      value={searchInput}
      onChange={(e) => setSearchInput(e.currentTarget.value)}
      name="search"
      id={inputId !== undefined && inputId}
      showLabel={false}
      type="text"
      placeholder={`${t('searchLabel')}...`}
    />
  );
};

export default TeamSearch;
