import { h } from 'preact';
import { useState } from 'preact/hooks';

import { FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';

const FormInput = (props) => {
  const [validating, setValidating] = useState(false);

  const { name, id, label, value, type, onChange, validator, valid: validProp,
          required = false, disabled = false, feedback, tip, children, ...otherProps } = props;

  const onBlur = () => {
    (validator !== undefined || validProp !== undefined) && setValidating(true);
    props.onBlur !== undefined && props.onBlur();
  };

  // isValid hierarchy:
  // 'valid' prop if defined
  // 'validator' result if validator is defined
  // False otherwise
  const isValid = validProp !== undefined ? validProp : validator !== undefined ? validator(value) : false;
  // isInvalid hierarchy:
  // Negation of isValid
  // However: will be false if input is not required and currently empty
  const isInvalid = !isValid && !(!required && value === '');

  return (
    <FormGroup>
      {props.showLabel && <Label for={id}>{label}{required && <span className="text-danger"> *</span>}</Label>}
      <Input
        name={name}
        id={id}
        type={type}
        value={value}
        onChange={(e) => onChange(e)}
        required={required}
        disabled={disabled}
        onBlur={() => onBlur()}
        valid={validating && isValid}
        invalid={validating && isInvalid}
        {...otherProps}
      />
      {children}
      {required && value === '' && <FormFeedback invalid="true">Kenttä ei voi olla tyhjä</FormFeedback>}
      {
        feedback !== undefined &&
        validating &&
        isInvalid &&
        value !== '' &&
        <FormFeedback invalid="true">{feedback}</FormFeedback>
      }
      {tip !== undefined && <FormText>{tip}</FormText>}
    </FormGroup>
  );
};

export default FormInput;
