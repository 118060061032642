import { h } from 'preact';

import SaveTeam from '../teams/saveTeam';

const Title = ({ title, subtitle, saveTeam }) => (
  <div class="title">
    {saveTeam ? <h2>{title}</h2> : <h1>{title}</h1>}
    {subtitle && (
      <div class="subheading">
        <h4>{subtitle}</h4>
        {saveTeam && <SaveTeam id={saveTeam.team_id} name={saveTeam.team_name} />}
      </div>
    )}
  </div>
);

export default Title;