import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';

import { Link } from 'preact-router/match';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
} from 'reactstrap';

import t from '../../util/i18n';
import { leagues } from '../../assets/consts';
import { formLinkUrl } from '../../assets/urls';
import { localeNames } from '../../assets/translations';

import Locale from '../providers/locale';
import TeamSearch from '../teams/teamSearch';
import NavbarToggle from './navbarToggle';

const Header = () => {
  const { locale, setLocale } = useContext(Locale);
  const [lang, setLang] = useState(locale);
  const [openMenu, setOpenMenu] = useState(null);

  const setLanguage = (lang) => {
    setLocale(lang);
    setLang(lang);
    setOpenMenu(null);
  };

  const setMenuOpen = (menuType) => {
    if (openMenu === menuType) {
      setOpenMenu(null);
    } else {
      setOpenMenu(menuType);
    }
  };

  const closeMenu = () => {
    setOpenMenu(null);
  };

  // Generic Nav item, using NavLink from react-router-dom
  const renderNavItem = (path, name) => (
    <NavItem onClick={() => setOpenMenu(null)}>
      <NavLink tag={Link} href={path}>{name}</NavLink>
    </NavItem>
  );

  const links = () => {
    const map = renderNavItem(formLinkUrl('map'), t('map'));
    const myTeams = renderNavItem(formLinkUrl('myTeams'), t('myTeams'));
    const leagueLinks = leagues.map(key =>
      renderNavItem(formLinkUrl('league', key), t(key)),
    );
    const restaurants = renderNavItem(formLinkUrl('restaurants'), t('restaurants'));
    return [map, myTeams, ...leagueLinks, restaurants];
  };

  // Language menu options
  const langItems = localeNames.map(obj => (
    <NavItem class="nav-link" onClick={() => setLanguage(obj.key)}>
      {obj.nameLong}
    </NavItem>
  ));

  return (
    <header class="header">
      <Navbar color="brand">
        <NavbarBrand tag={Link} href="/">{t('appTitle')}</NavbarBrand>
        <div class="navbar-actions">
          <NavbarToggle
            onClick={() => setMenuOpen('lang')}
            isOpen={openMenu === 'lang'}
            text={localeNames.find(obj => obj.key === lang).name}
          />
          <NavbarToggle
            onClick={() => setMenuOpen('search')}
            isOpen={openMenu === 'search'}
            icon="search"
          />
          <NavbarToggle
            onClick={() => setMenuOpen('nav')}
            isOpen={openMenu === 'nav'}
            icon="menu"
          />
        </div>
        <Collapse isOpen={openMenu !== null} navbar>
          <div class="collapse-content">
            <Nav navbar>
              {openMenu === 'nav' && links()}
              {openMenu === 'lang' && langItems}
            </Nav>
            {openMenu === 'search' &&
              <div class="p-top-l"><TeamSearch searchCallback={() => setMenuOpen('search')} /></div>
            }
          </div>
        </Collapse>
      </Navbar>
      {openMenu !== null && <div class="outside-header" onClick={() => closeMenu()} />}
    </header>
  );
};

export default Header;
