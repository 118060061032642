const icons = {
  clock: 'M12,20C16.42,20 20,16.42 20,12C20,7.58 16.42,4 12,4C7.58,4 4,7.58 4,12C4,16.42 7.58,20 12,20M12,2C17.52,2 22,6.48 22,12C22,17.52 17.52,22 12,22C6.47,22 2,17.5 2,12C2,6.48 6.48,2 12,2M12.5,7V12.25L17,14.92L16.25,16.15L11,13V7H12.5Z',
  close: 'M12.71,12l6.64-6.65a.49.49,0,1,0-.7-.7L12,11.29,5.35,4.65a.49.49,0,0,0-.7.7L11.29,12,4.65,18.65a.48.48,0,0,0,0,.7.48.48,0,0,0,.7,0L12,12.71l6.65,6.64a.48.48,0,0,0,.7,0,.48.48,0,0,0,0-.7Z',
  gps_fixed: 'M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
  gps_not_fixed: [
    { type: 'path', fill: 'none', value: 'M0 0h24v24H0z' },
    { type: 'path', value: 'M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z' },
  ],
  gps_off: [
    { type: 'path', fill: 'none', value: 'M0 0h24v24H0z' },
    { type: 'path', value: 'M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06c-1.13.12-2.19.46-3.16.97l1.5 1.5C10.16 5.19 11.06 5 12 5c3.87 0 7 3.13 7 7 0 .94-.19 1.84-.52 2.65l1.5 1.5c.5-.96.84-2.02.97-3.15H23v-2h-2.06zM3 4.27l2.04 2.04C3.97 7.62 3.25 9.23 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c1.77-.2 3.38-.91 4.69-1.98L19.73 21 21 19.73 4.27 3 3 4.27zm13.27 13.27C15.09 18.45 13.61 19 12 19c-3.87 0-7-3.13-7-7 0-1.61.55-3.09 1.46-4.27l9.81 9.81z' },
  ],
  heart: 'M12,19.47a.51.51,0,0,1-.36-.15L4.72,12.11l0,0A4.5,4.5,0,1,1,12,7a4.5,4.5,0,1,1,7.32,5.09l-.05.06-6.91,7.19A.48.48,0,0,1,12,19.47ZM5.37,11.34v0L12,18.25l6.61-6.89,0,0A3.5,3.5,0,1,0,12.5,9a.5.5,0,1,1-1,0,3.5,3.5,0,1,0-6.13,2.31Z',
  heart_filled: 'M16,5a4.5,4.5,0,0,0-4,2.44,4.5,4.5,0,1,0-7.35,5.06l0,.05,0,0,6.92,7.21A.51.51,0,0,0,12,20a.48.48,0,0,0,.36-.16l6.91-7.19.05-.06A4.49,4.49,0,0,0,16,5Z',
  menu: 'M19.5,12.12a.5.5,0,0,1-.5.5H5a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H19A.5.5,0,0,1,19.5,12.12Zm-.58,6.5h-14a.5.5,0,0,0-.5.5.5.5,0,0,0,.5.5h14a.5.5,0,0,0,.5-.5A.5.5,0,0,0,18.92,18.62ZM5,5.62H19a.5.5,0,0,0,.5-.5.5.5,0,0,0-.5-.5H5a.5.5,0,0,0-.5.5A.5.5,0,0,0,5,5.62Z',
  pin: 'M12,11.5C10.62,11.5 9.5,10.38 9.5,9C9.5,7.62 10.62,6.5 12,6.5C13.38,6.5 14.5,7.62 14.5,9C14.5,10.38 13.38,11.5 12,11.5M12,2C8.13,2 5,5.13 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9C19,5.13 15.87,2 12,2Z',
  search: 'M19.87,19.17l-5.31-5.32a6,6,0,1,0-.71.71l5.32,5.31a.48.48,0,0,0,.7,0A.48.48,0,0,0,19.87,19.17ZM5,10a5,5,0,1,1,5,5A5,5,0,0,1,5,10Z',
  trash: 'M19,6.25H15.48v-2a.5.5,0,0,0-.5-.5H9a.5.5,0,0,0-.5.5v2H5a.5.5,0,0,0,0,1H6v12.5a.5.5,0,0,0,.5.5h11a.5.5,0,0,0,.5-.5V7.25h1a.5.5,0,0,0,0-1ZM9.52,4.75h5v1.5h-5ZM17,19.25H7v-12H17ZM10.6,9.36v7.75a.5.5,0,0,1-.5.5.5.5,0,0,1-.5-.5V9.36a.5.5,0,0,1,.5-.5A.5.5,0,0,1,10.6,9.36Zm3.8.11v7.76a.5.5,0,0,1-.5.5.51.51,0,0,1-.5-.5V9.47a.51.51,0,0,1,.5-.5A.5.5,0,0,1,14.4,9.47Z',
};

export default icons;
