import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { adsBaseUrl, formContentUrl } from '../../assets/urls';
import { parseContent } from '../../util/parsers';
import { customFetch } from '../../util/customFetch';

const Ads = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const result = await customFetch(formContentUrl('ads'), {
          headers: {
            Accept: 'application/json',
          },
        });

        const adContent = parseContent(result);

        setAds(adContent);
      } catch (error) {
        console.error(error);
      }
    };

    fetchAds();
  }, []);

  // Section ID is to unblock from adbockers
  return (
    <section id="sponssit">
      {ads.length ? (
        <div class="limit-width content-padded sponssit-container">
          {ads.map(ad => {
            if (ad.image.thumbnail === undefined) {
              console.warn(`Ad id ${ad.id} (${ad.title}) image url not found`);
              return null;
            }
            const imgUrl = `${adsBaseUrl}${ad.image.thumbnail !== undefined ? ad.image.thumbnail : ad.image.filename}`;
            return (
              <div class="image-container" key={ad.id}>
                <a href={ad.targeturl}>
                  <img src={imgUrl} />
                </a>
              </div>
            );
          })}
        </div>
      ) : null}
    </section>

  );
}

export default Ads;