import { v4 as uuidv4 } from 'uuid';

import { HttpError } from './errors';

export const customFetch = async (url, config) => {
  const response = await fetch(url, config);
  if (!response.ok) {
    throw new HttpError(response.status, response.json());
  }

  const body = await response.json();

  return body;
};

// FIXME: I have no idea if this works
export const backGroundFetch = async (url, config) => {
  if (!('BackgroundFetchManager' in self)) {
    console.warn('Browser does not support background fetch');
    return;
  }

  const reg = await navigator.serviceWorker.ready;
  const bgFetch = await reg.backgroundFetch.fetch(uuidv4(), new Request(url, { method: 'GET', mode: 'no-cors' }));
  console.log(bgFetch);

  const record = await bgFetch.match(url);
  console.log(record);
  const ready = await record.responseReady;
  console.log(ready);
  console.log(bgFetch);

};
