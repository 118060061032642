import { h, Component } from 'preact';

// This component prevents errors in server-side pre-renders
// by only importing the Map component if the code is being run in a browser
export default class MapContainer extends Component {
  constructor() {
    super()

    this.state = {
      hasComponent: false,
    };

    this.Map = undefined;

    if (typeof window !== 'undefined') {
      import('./map').then((Map) => {
        this.Map = Map.default;

        this.setState({
          hasComponent: true,
        });
      });
    }
  }
  
  render() {
    if (!this.state.hasComponent) return null;

    return <this.Map />;
  }
}