import { createContext } from 'preact';
import { useState, useEffect } from 'preact/hooks';

import * as storage from '../../util/localData';

const initialTeams = storage.getSavedTeams();

const SavedTeams = createContext();

export const SavedTeamsProvider = (props) => {
  const [savedTeams, setSavedTeams] = useState(initialTeams);

  useEffect(() => {
    storage.setSavedTeams(savedTeams);
  }, [savedTeams]);

  return (
    <SavedTeams.Provider value={{ savedTeams, setSavedTeams }}>
      {props.children}
    </SavedTeams.Provider>
  );
};

export default SavedTeams;