import { h } from 'preact';

import { CSSTransition } from 'react-transition-group';
import { Spinner as SpinnerElement } from 'reactstrap';

// Match to style/components/_fade.scss
const fadeIn = 400;
const fadeOut = 200;

// FIXME: fade out does not work, move away from CSSTransition?
const Spinner = ({ visible }) => (
  <CSSTransition
    in={visible}
    timeout={{
      enter: fadeIn,
      exit: fadeOut,
    }}
    classNames="fade"
    unmountOnExit
  >
    <div className="overlay">
      <SpinnerElement color="accent" className="spinner-large" />
    </div>
  </CSSTransition>
);

export default Spinner;
