import { h, Component, Fragment } from 'preact';
import { Router } from 'preact-router';

import { getLinkUrl } from '../assets/urls';
import { LocaleProvider } from './providers/locale';
import { SavedTeamsProvider } from './providers/savedTeamsProvider';

// Code-splitting is automated for routes
import Home from './routes/home';
import MyTeams from './routes/myTeams';
import League from './routes/league';
import Team from './routes/team';
import Block from './routes/block';
import Field from './routes/field';
import BigScreen from './routes/bigscreen';
import ContentPage from './externalContent/contentPage';
import MapContainer from './map/mapContainer';
import Header from './appLayout/header';
import Footer from './appLayout/footer';
import Ads from './appLayout/ads';
import Restaurants from './routes/restaurants';

import '../style/index.scss';

export default class App extends Component {

  /** Gets fired when the route changes.
   *	@param {Object} event	"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */
  handleRoute = e => {
    this.currentUrl = e.url;
  };

  render() {
    return (
      <div id="app">
        <LocaleProvider>
          <SavedTeamsProvider>
            <Router onChange={this.handleRoute}>
              <BigScreen path="/bigscreen/:gameCount?" />
              <BigScreen path="/bigscreen/frm/:gameCount?" frm />
              <Fragment default>
                <main>
                  <Header />
                  <div class="app-content">
                    <Router>
                      <Home path="/" />
                      <MyTeams path={`/${getLinkUrl('myTeams')}`} />
                      <League path={`/${getLinkUrl('league')}/:league`} />
                      <Team path={`/${getLinkUrl('team')}/:teamId`} />
                      <Block path={`/${getLinkUrl('block')}/:blockId`} />
                      <Field path={`/${getLinkUrl('field')}/:fieldId`} />
                      <MapContainer path={`/${getLinkUrl('map')}`} />
                      <Restaurants path={`/${getLinkUrl('restaurants')}`} />
                      <ContentPage path={`/${getLinkUrl('news')}/:contentSlug`} contentType="news" />
                      <ContentPage path={`/${getLinkUrl('pages')}/:contentSlug`} contentType="pages" />
                      <ContentPage path={`/${getLinkUrl('entry')}/:contentSlug`} contentType="entries" />
                    </Router>
                  </div>
                  <Footer />
                </main>
                <Ads />
              </Fragment>
            </Router>
          </SavedTeamsProvider>
        </LocaleProvider>
      </div>
    );
  }
}
