export class HttpError extends Error {
  status;
  reason;

  constructor(status, reason) {
    super(`Http error ${status}: ${reason}`);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'HttpError';
    this.status = status;
    this.reason = reason;
  }
}
