import { h } from 'preact';

import icons from '../../assets/icons.js';
import type from '../../util/type';

/**
 * Add more options and implementations as needed
 * @typedef {('path'|'circle')} IconElementType
 */

/**
 * Icon element
 * @typedef {Object} IconElement
 * @property {IconElementType} type
 * @property {string} value
 * @property {'none'|undefined} fill Add more options and implementations as needed
 */

/**
 * Create SVG element from asset
 * @param {(string|IconElement[])} icon
 * @param {string} colorClass CSS class for the color
 * @param {number} size
 */
const renderSvg = (icon, className, size) => {
  if (type(icon) === 'array') {
    return (
      <svg class="icon" width={size || 24} height={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        {
          icon.map((el) => {
            switch (el.type) {
              case 'path': {
                return <path d={el.value} fill={el.fill} class={el.fill === undefined ? className : ''} />;
              }
              case 'circle': {
                return <circle {...el.value} class={className} />;
              }
            }
          })
        }
      </svg>
    );
  }

  return (
    <svg class="icon" width={size || 24} height={size || 24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d={icon} class={className} />
    </svg>
  );
};

const Icon = ({ name, className, size }) => {
  const icon = icons[name];

  if (icon === undefined) {
    console.error(`Icon: icon with the name ${name} could not be found from assets.`);
    return null;
  }

  return renderSvg(icon, className, size);
};

export default Icon;