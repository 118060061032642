import { useContext } from 'preact/hooks';

import Locale from '../components/providers/locale';
import { translations } from '../assets/translations';

/**
 * Returns translated texts according to set locale
 * @param {string} key string key
 */
const t = (key) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const locale = useContext(Locale).locale;

  const translation = translations[locale][key];

  if (translation === undefined) {
    console.error(`No translation found for key ${key} in locale ${locale}`);
    return key;
  }

  return translation;
};

/**
 * Returns locale time string
 * @param {Date} date Date object
 */
export const localeTimeString = (date) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const locale = useContext(Locale).locale;

  return date.toLocaleTimeString(locale, { hour: 'numeric', minute: '2-digit' });
};

export const getCountTextKey = (count, key) => (count === 1 ? `${key}CountSingle` : `${key}Count`);

export default t;