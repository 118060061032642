import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import t from '../../util/i18n';

const TIMEOUT = 3500;

const FeedbackMessage = ({ messageKey }) => {
  const [visible, setVisible] = useState(false);
  const [animation, setAnimation] = useState(null);
  const [timeout, setStateTimeout] = useState(undefined);

  useEffect(() => {
    // The returned function from useEffect-hook is fired when the component will unmount
    // Use it to clear any async actions to prevent memory leaks
    // This needs its own hook, otherwise the hook is fired constantly
    const cleanup = () => {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
    };

    return cleanup;
  }, [timeout]);

  useEffect(() => {
    if (messageKey) {
      setAnimation('fade-in');
      setVisible(true);
      const to = setTimeout(() => {
        setAnimation('fade-out');
      }, TIMEOUT);

      setStateTimeout(to);
    }
  }, [messageKey]);

  const toggleVisibility = () => {
    if (animation === 'fade-out') {
      setVisible(false);
    }
    setAnimation(null);
  };

  return (
    visible && messageKey && (
      <div
        class={`feedback-message ${animation}`}
        onAnimationEnd={toggleVisibility}
      >
        {t(messageKey)}
      </div>
    )
  );
};

export default FeedbackMessage;