import { locales } from '../assets/translations';

const localeName = 'locale';
const savedTeamsName = 'savedTeams';

export const getLocaleOrDefault = () => {
  if (typeof window !== 'undefined') { // For prerender in production builds
    const locale = localStorage.getItem(localeName);
    return locale !== null ? locale : locales[0];
  }

  return locales[0];
};

export const setLocale = (locale) => {
  if (!locales.includes(locale)) {
    console.error(`Locale name ${locale} not found in locales`);
  }

  localStorage.setItem(localeName, locale);
};

export const getSavedTeams = () => {
  if (typeof window !== 'undefined') { // For prerender in production builds
    return JSON.parse(localStorage.getItem(savedTeamsName)) || [];
  }

  return [];
};

export const setSavedTeams = (team) => {
  localStorage.setItem(savedTeamsName, JSON.stringify(team));
};
