import { Fragment, h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { formContentUrl } from '../../assets/urls';
import { parseContent } from '../../util/parsers';
import { customFetch } from '../../util/customFetch';

import Title from '../common/title';
import Spinner from '../common/spinner';
import FeedbackMessage from '../common/feedbackMessage';

/**
 * Game dto object
 * @typedef {Object} Props
 * @property {('news'|'pages'|'entries')} contentType
 * @property {string} contentSlug
 */

/**
 * ContentPage
 * @param {Props} props
 */
const ContentPage = ({ contentType, contentSlug }) => {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      try {
        const result = await customFetch(formContentUrl(contentType), {
          headers: {
            Accept: 'application/json',
          },
        });

        const content = parseContent(result);
        console.log(content);

        setContent(content);
      } catch (error) {
        // TODO: show error
        console.error(error);
      }
      setLoading(false);
    };

    fetchContent();
  }, [contentType, contentSlug]);

  const found = content.find(element => element.slug === contentSlug);
  const messageKey = (found === undefined && !loading) ? 'contentNotFound' : undefined

  return (
    <div>
      <Spinner visible={loading} />
      <FeedbackMessage messageKey={messageKey} />
      {found !== undefined &&
        <Fragment>
          <Title title={found.title} />
          {(found.body !== undefined && found.body !== '') ?
            // eslint-disable-next-line react/no-danger
            <div class="content-padded" dangerouslySetInnerHTML={{ __html: found.body }}  /> :
            // eslint-disable-next-line react/no-danger
            <div class="content-padded" dangerouslySetInnerHTML={{ __html: found.teaser }}  />
          }
        </Fragment>
      }
    </div>
  );
};

export default ContentPage;
