import { h } from 'preact';
import { useState } from 'preact/hooks';

import FormInput from './formInput';

const Autocomplete = (props) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(undefined);

  const { suggestions, onSuggestionSelect, value, onChange, name, id, label, type, ...otherProps } = props;

  const filteredSuggestions = suggestions.filter((suggestion) =>
    suggestion.name.toLowerCase().includes(value.toLowerCase()) && suggestion.name !== value);

  if (activeSuggestion !== undefined && activeSuggestion > filteredSuggestions.length - 1) {
    setActiveSuggestion(filteredSuggestions.length - 1);
  }

  /**
   * Handle key press
   * @param {React.KeyboardEvent<HTMLElement>} e
   */
  const onKeyDown = (e) => {
    switch (e.keyCode) {
      case 38: { // Arrow up
        e.preventDefault();

        if (activeSuggestion === undefined) {
          return;
        } else if (activeSuggestion === 0) {
          setActiveSuggestion(undefined);
          return;
        }

        setActiveSuggestion(activeSuggestion - 1);
        return;
      }
      case 40: { // Arrow down
        e.preventDefault();

        if (!showSuggestions) {
          setShowSuggestions(true);
        }

        if (activeSuggestion === filteredSuggestions.length - 1 || filteredSuggestions.length === 0) {
          return;
        } else if (activeSuggestion === undefined) {
          setActiveSuggestion(0);
          return;
        }

        setActiveSuggestion(activeSuggestion + 1);
        return;
      }
      case 13: { // Enter
        e.preventDefault();

        if (activeSuggestion !== undefined) {
          onSuggestionSelect(filteredSuggestions[activeSuggestion]);
        }
        return;
      }
      case 27: { // Escape
        setShowSuggestions(false);
        setActiveSuggestion(undefined);
        return;
      }
    }
  };

  /**
   * Handle mouse click
   * @param {React.MouseEvent<HTMLLIElement, MouseEvent>} e
   * @param {Object} suggestion
   * @param {number} index
   */
  const onClick = (e, suggestion, index) => {
    console.log(e);
    console.log(suggestion.name);
    setActiveSuggestion(index);
    setShowSuggestions(false);
    onSuggestionSelect(filteredSuggestions[index]);

    // Synthesize and dispatch click event, not necessarily required but good form
    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    });
    const elem = document.getElementById(suggestion.id);
    elem !== null && elem.dispatchEvent(event); // Should always be fired
  };

  const onBlur = () => {
    setShowSuggestions(false);
  };

  const suggestionsListComponent = (
    <ul className="suggestions">
      {filteredSuggestions.map((suggestion, index) => {
        let className;

        if (index === activeSuggestion) {
          className = 'suggestion-active';
        }

        return (
          <li
            id={suggestion.id}
            className={`${className} team-name`}
            key={suggestion.id}
            onMouseDown={(e) => e.preventDefault() /* hackery to prevent the textfield from blurring */}
            onMouseUp={(e) => onClick(e, suggestion, index)}
          >
            {suggestion.name}
          </li>
        );
      })}
    </ul>
  );

  // TODO: can we remove div tags?
  return (
    <div>
      <FormInput
        name={name}
        id={id !== undefined ? id : name}
        label={label}
        type={type}
        onFocus={() => setShowSuggestions(true)}
        onKeyDown={onKeyDown}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
        {...otherProps}
      >
        {showSuggestions && filteredSuggestions.length > 0 && suggestionsListComponent}
      </FormInput>
    </div>
  );
};

export default Autocomplete;
