const fi = {
  language: 'FI',
  languageLong: 'Suomi',
  appTitle: 'Kyykkä-App',
  kyykka: 'Akateemisen kyykän MM-kilpailut',
  tournamentOffice: 'Kysymyksiä? Kisatoimisto on avoinna koko kyykkäpäivän ajan!',
  homeTitle: 'Tulospalvelu',
  manageMyTeams: 'Hallinnoi omia joukkueita',
  description: 'Seuraa Akateemisen kyykän MM-kilpailujen tuloksia reaaliajassa!',
  searchLabel: 'Hae joukkuetta',
  yle: 'Yleinen sarja',
  pro: 'Prosarja',
  frm: 'Firma-sarja',
  block: 'Lohko',
  round: 'Kierros',
  start_block: 'Alkulohko',
  continuation_block: 'Jatkolohko',
  quarter_final: 'Puolivälierä',
  semi_final: 'Semifinaali',
  bronze_game: 'Pronssiottelu',
  final: 'Finaali',
  team: 'Joukkue',
  field: 'Kenttä',
  scoreboard: 'Lohkopisteet - kierros',
  games: 'Pelit',
  gamesCount: 'peliä',
  gamesCountSingle: 'peli',
  finishedGames: 'Pelatut',
  ongoingGames: 'Käynnissä',
  upcomingGames: 'Tulevat',
  finishedNotFound: 'Ei pelattuja pelejä',
  ongoingNotFound: 'Ei käynnissä olevia pelejä',
  upcomingNotFound: 'Ei tulevia pelejä',
  wins: 'Voitot',
  ties: 'Tasan',
  losses: 'Häviöt',
  mean: 'KA',
  points: 'Pts',
  ongoingGame: 'Joukkue pelaa parhaillaan kentällä',
  teamSaved: 'Tallennettu omiin joukkueisiin',
  teamRemoved: 'Poistettu omista joukkueista',
  remove: 'Poista',
  myTeams: 'Omat joukkueet',
  noSavedTeams: 'Et ole vielä tallentanut joukkueita. Etsi ja lisää joukkueita haun kautta!',
  browseGames: 'Selaa sarjojen pelejä',
  nextGame: 'Seuraava peli kentällä',
  atTime: 'klo',
  map: 'Kartta',
  geolocationErrorType1: 'Sivustolla ei ole lupaa sijaintisi hakemiseen.',
  geolocationErrorType2: 'Sijaintisi hakemisessa tapahtui virhe.',
  contentNotFound: 'Hakemaasi sisältöä ei löytynyt.',
  newsListTitle: 'Ajankohtaista',
  tournamentNotReadyTitle: 'Huom! Turnauksen aikataulu ei ole ajantasalla!',
  restaurants: "Ravintolat",
  openingTimes: "Aukioloajat",
  coffee: "Kahvi",
  tea: "Tee",
  hotJuice: "Kuuma mehu",
  donut: "Munkki",
  glutFreeDonut: "Gluteeniton munkki",
  candyBag: "Sotilaskoti -karkkipussi",
  grill: 'grilli',
  vendaceRove: "Muikkurove & valkosipulikastike",
  vendaceDish: "Muikkuannos",
  sausagePotatoes: "Makkaraperunat",
  largeSausagePotatoes: "Makkaraperunat jätti",
  vegetarianDishes: "Kasvis, vegaaniannokset",
  pancake: "Lätty",
  muchosNachos: "Muchos Nachot (Valitse täyte: BBQ-maustettu nyhtöpossu, Teriyakimaustettua nyhtökanaa, BBQ-maustettua härkisrouhetta)",
  nachosAddInf: "Nachoja, Ionin tomaattikastiketta, kuumaa cheddarkastiketta, nokare turkkilaista jugurttia, jalopenoja sekä paahdettua pepperonipaprikaa",
  overTheTopFrenchFries: "Överiranskikset",
  overTheTopFrenchFriesAdd: "Runsas lautasellinen ranskalaisia, suomalaista pekonimurua, aurajuustoa ja kuumaa cheddarsoossia",
  parmesanFries: "Parmesaaniranet",
  parmesanFriesAddInfo: "Ranskalaisia, parmesaania, persiljasilppua ja chili-basilikadippi",
  food: "Ruoka",
  bottlesCans: "Pullossa/tölkissä",
  lapinKulta: "Lapin kulta pure luomu (gluteeniton) 4,5%",
  longDrink: "Hartwall lonkero 5,5%",
  pineappleLongDrink: "Hartwall ananaslonkero 5,5%",
  wines: "Viinit",
  piccolo: "Piccolo kuohuviini",
  redWine: "Punaviini",
  whiteWine: "Valkoviini",
  hotBeverages: "Kuumat juomat",
  mintChocolate: "Minttukaakao/ Jallukaakao kermavaahdolla",
  drink: "Drinkki",
  vodkaCranberryLime: "Vodka-karpalo-lime",
  smallCups: "Pikkumukit",
  shots: "Smirnoff vodka/ Minttuviina/ Jaloviina/ Jägermeister",
  peaSoup: "Hernekeitto",
  foodTicket: "Ruokalipukkeella",
  vegePeaSoup: "Kasvishernekeitto",
  hash: "Pyttipannu",
  vegeHash: "Kasvispyttipannu"
};

const en = {
  language: 'EN',
  languageLong: 'English',
  appTitle: 'Kyykkä-App',
  kyykka: 'World Championships of Academic kyykkä',
  tournamentOffice: 'Questions? The tournament office is open all day during the tournament!',
  homeTitle: 'Live Scores',
  manageMyTeams: 'Manage my teams',
  description: 'Follow the scores of Academic World Championship of Kyykkä in real time!',
  searchLabel: 'Search for a team',
  yle: 'Open league',
  pro: 'Pro league',
  frm: 'Company league',
  block: 'Group',
  round: 'Round',
  start_block: 'Group stage 1',
  continuation_block: 'Group stage 2',
  quarter_final: 'Quarterfinal',
  semi_final: 'Semifinal',
  bronze_game: 'Bronze game',
  final: 'Final',
  team: 'Team',
  field: 'Field',
  scoreboard: 'Group Scoreboard - round',
  games: 'Games',
  gamesCount: 'games',
  gamesCountSingle: 'game',
  finishedGames: 'Finished',
  ongoingGames: 'Ongoing',
  upcomingGames: 'Scheduled',
  finishedNotFound: 'No finished games',
  ongoingNotFound: 'No ongoing games',
  upcomingNotFound: 'No scheduled games',
  wins: 'Wins',
  ties: 'Ties',
  losses: 'Losses',
  mean: 'Mean',
  points: 'Pts',
  ongoingGame: 'The team is playing now on field',
  teamSaved: 'Saved to your teams',
  teamRemoved: 'Removed from your teams',
  remove: 'Remove',
  myTeams: 'My Teams',
  noSavedTeams: 'You haven\'t saved any teams yet. Try searching for a team and adding one!',
  browseGames: 'Browse games by leagues',
  nextGame: 'Next game on field',
  atTime: 'at',
  map: 'Map',
  geolocationErrorType1: "The page doesn't have permission to get your location",
  geolocationErrorType2: 'An error occurred while getting your location.',
  contentNotFound: 'The content you were accessing was not found.',
  newsListTitle: 'Recent news',
  tournamentNotReadyTitle: 'Note! Tournament schedule is not up to date!',
  restaurants: "Restaurants",
  openingTimes: "Opening hours",
  coffee: "Coffee",
  tea: "Tea",
  hotJuice: "Hot juice",
  donut: "Donut",
  glutFreeDonut: "Gluten-free donut",
  candyBag: "Sotilaskoti candy bag",
  grill: 'grill',
  vendaceRove: "Fried vendace & garlic sauce",
  vendaceDish: "Vendace dish",
  sausagePotatoes: "Potato and sausage hash",
  largeSausagePotatoes: "Large potato and sausage hash",
  vegetarianDishes: "Vegetarian, vegan portions",
  pancake: "Pancake",
  muchosNachos: "Muchos Nachos (Choose a filling: Finnish BBQ-flavoured pulled pork, Finnish teriyaki seasoned pulled chicken, BBQ-flavoured Härkis® - vegetable protein product)",
  nachosAddInf: "Nachos, Ioni's tomato salsa, hot cheddar sauce, Turkish yogurt, jalapenos, and roasted pepperoni peppers",
  overTheTopFrenchFries: "Oh my fries!",
  overTheTopFrenchFriesAdd: "French fries, Finnish bacon, AURA® blue cheese, and hot cheddar sauce",
  parmesanFries: "Fries with parmesan",
  parmesanFriesAddInfo: "French fries, parmesan cheese, chopped parsley and chili-basil dip",
  food: "Food",
  bottlesCans: "Bottle/can",
  lapinKulta: "Lapin kulta pure organic (gluten-free) 4,5%",
  longDrink: "Hartwall long drink 5,5%",
  pineappleLongDrink: "Hartwall pineapple long drink 5,5%",
  wines: "Wines",
  piccolo: "Piccolo sparkling wine",
  redWine: "Red wine",
  whiteWine: "White wine",
  hotBeverages: "Hot drinks",
  mintChocolate: "Mint hot chocolate/ Jallu (Jaloviina) hot chocolate with whipped cream",
  drink: "Drink",
  vodkaCranberryLime: "Vodka-cranberry-lime",
  smallCups: "Small cups",
  shots: "Smirnoff vodka/ Mint liqueur/ Jaloviina/ Jägermeister",
  peaSoup: "Pea soup",
  foodTicket: "With a food ticket",
  vegePeaSoup: "Vegetarian pea soup",
  hash: "Hash",
  vegeHash: "Vegetarian hash"
};

export const translations = {
  'fi-FI': fi, // The first option will be the default if the user has not set a language, see localData.js
  'en-US': en,
};

export const locales = Object.keys(translations);
export const localeNames = locales.map((key) => (
  { key, name: translations[key].language, nameLong: translations[key].languageLong }
));