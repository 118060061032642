/**
 * Game dto object
 * @typedef {Object} GameDto
 * @property {number} game_id
 * @property {number} paper_id
 * @property {string} league_id
 * @property {number} round
 * @property {(0|1)} game_start boolean, 0 or 1
 * @property {(0|1)} game_1st
 * @property {(0|1)} game_2nd
 * @property {(0|1)} game_ot
 * @property {(0|1)} game_end
 * @property {string} schedule_start timestamp as string
 * @property {string} schedule_end
 * @property {string} time_start
 * @property {string} time_end
 * @property {string} print_start
 * @property {string} print_end
 * @property {number} time_delay
 * @property {(number|null)} team1_paper_id
 * @property {(number|null)} team2_paper_id
 * @property {number} judge_id
 * @property {number} team1_id
 * @property {number} team2_id
 * @property {string} team1_name
 * @property {string} team2_name
 * @property {number} team1_1st_score
 * @property {number} team2_1st_score
 * @property {number} team1_2nd_score
 * @property {number} team2_2nd_score
 * @property {number} team1_ot_score
 * @property {number} team2_ot_score
 * @property {number} team1_score
 * @property {number} team2_score
 * @property {number} teamw_id
 * @property {(0|1)} team1_w
 * @property {(0|1)} team2_w
 * @property {(0|1|null)} team_tie
 * @property {number} gamew_id
 * @property {number} paperw_id
 * @property {number} court_id
 * @property {number} block_id
 */

/**
 * Game object
 * @typedef {Object} Game
 * @property {number} game_id
 * @property {number} paper_id
 * @property {string} league_id
 * @property {number} round
 * @property {boolean} game_start
 * @property {boolean} game_1st
 * @property {boolean} game_2nd
 * @property {boolean} game_ot
 * @property {boolean} game_end
 * @property {Date} print_start
 * @property {Date} print_end
 * @property {number} time_delay
 * @property {(number|null)} team1_paper_id
 * @property {(number|null)} team2_paper_id
 * @property {number} judge_id
 * @property {number} team1_id
 * @property {number} team2_id
 * @property {string} team1_name
 * @property {string} team2_name
 * @property {number} team1_1st_score
 * @property {number} team2_1st_score
 * @property {number} team1_2nd_score
 * @property {number} team2_2nd_score
 * @property {number} team1_ot_score
 * @property {number} team2_ot_score
 * @property {number} team1_score
 * @property {number} team2_score
 * @property {number} teamw_id
 * @property {boolean} team1_w
 * @property {boolean} team2_w
 * @property {boolean} team_tie
 * @property {number} gamew_id
 * @property {number} paperw_id
 * @property {number} court_id
 * @property {string} field
 * @property {number} block_id
 */

const numberToBoolean = (x) => x === 1;

/**
 * Parse a game object from Tulospalvelu
 * @param {GameDto} game Game dto object
 * @param fieldrewrite
 * @returns {Game} converted Game
 */
export const parseGame = (game, fieldrewrite) => {
  let newGame = { ...game };

  try {
    newGame = {
      ...game,
      field: fieldrewrite != null ? fieldrewrite[game.court_id] ?? game.court_id : game.court_id,
      game_start: numberToBoolean(game.game_start),
      game_1st: numberToBoolean(game.game_1st),
      game_2nd: numberToBoolean(game.game_2nd),
      game_ot: numberToBoolean(game.game_ot),
      game_end: numberToBoolean(game.game_end),
      print_start: new Date(game.print_start.split(' ').join('T')),
      print_end: new Date(game.print_end.split(' ').join('T')),
      team1_w: numberToBoolean(game.team1_w),
      team2_w: numberToBoolean(game.team2_w),
      team_tie: numberToBoolean(game.team_tie),
    };
  } catch (error) {
    console.error(error);
  }

  return newGame;
};

export const parseGamesObject = (games, fieldrewrite) => ({
  upcoming: games.upcoming.map(val => parseGame(val, fieldrewrite)),
  ongoing: games.ongoing.map(val => parseGame(val, fieldrewrite)),
  finished: games.finished.map(val => parseGame(val, fieldrewrite)),
});

export const parseGameCountObject = (gameCount) => ({
  upcoming: gameCount.upcoming[0].count,
  ongoing: gameCount.ongoing[0].count,
  finished: gameCount.finished[0].count,
})

export const parseContent = (content) => content.map(val => ({
  id: val.id,
  status: val.status,
  createdAt: new Date(val.createdAt),
  modifiedAt: val.modifiedAt !== null ? new Date(val.modifiedAt) : undefined,
  publishedAt: val.publishedAt !== null ? new Date(val.publishedAt) : undefined,
  depublishedAt: val.depublishedAt !== null ? new Date(val.depublishedAt) : undefined,
  slug: val.fieldValues.slug,
  body: val.fieldValues.body,
  image: val.fieldValues.image,
  title: val.fieldValues.title,
  teaser: val.fieldValues.teaser,
  targeturl: val.fieldValues.targeturl,
  link: val.extras.link,
}));