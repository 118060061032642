import { h } from 'preact';

import t from '../../util/i18n';

const Footer = () => (
  <footer>
    <address class="limit-width content-padded">
      <h4>{t('kyykka')}</h4>
      <a href="mailto:info@kyykka.fi">info@kyykka.fi</a>
      <br />
      {t('tournamentOffice')}
    </address>
  </footer>
);

export default Footer;