import { createContext } from 'preact';
import { useState, useEffect } from 'preact/hooks';

// FIXME nimetään localeProvideriksi

import * as storage from '../../util/localData';

const initialLocale = storage.getLocaleOrDefault();

const Locale = createContext();

export const LocaleProvider = (props) => {
  const [locale, setLocale] = useState(initialLocale);

  useEffect(() => {
    storage.setLocale(locale);
  }, [locale]);

  return (
    <Locale.Provider value={{ locale, setLocale }}>
      {props.children}
    </Locale.Provider>
  );
};

export default Locale;