import { h } from 'preact';

import Icon from '../common/icon';

const NavbarToggle = ({ icon, text, isOpen, onClick }) => {
  const getContent = () => {
    if (isOpen) {
      return <Icon name="close" class="text" />;
    }
    return text ? text : <Icon name={icon} class="text" />;
  };

  return (
    <button
      type="button"
      class="navbar-toggle"
      onClick={onClick}
    >
      <div class="navbar-toggle-content">{getContent()}</div>
    </button>
  );
};

export default NavbarToggle;